body{
	font-family: var(--site-font);
}
.product-table-wrapper{
	height:calc(100% - 194.75px) !important;
	overflow-y: auto;
}
.custom-cart-scroll > div:nth-child(3) > div {
	background-color: rgba(0,0,0,.3) !important;
	border-radius: 57px !important;
	width: 6px !important;
	left: 0px !important;
}
.custom-cart-scroll{
	overflow-y: auto !important;
	overflow-x: hidden !important;
	max-height: 100% !important;
}
.custom-cart-scroll > div:nth-child(1){
	max-height: 100% !important;
	overflow: hidden !important;
	overflow-x: hidden !important;
	min-height: unset !important;
	margin-bottom: 0 !important;
	position: unset !important;
}
.product-table {
	padding-bottom: 30px;
}
.product-table-inner{
	height:100% !important;
}
.cart-items-list{
	padding: 10px 10px;
	border-bottom: 1px solid var(--border-color);
}
.product-image-wrap img{
	object-fit: contain;
}
.product-cart-info{
	flex: 0 0 50%;
	max-width: 50%;
	padding-right: 10px;
}
.product-cart-name {
	font-size: 15px;
	color: var(--black-color);
	text-shadow: 0px 0px 0px;
}
.product-price-wrapper{
	flex: 0 0 10%;
	max-width: 10%;
}
.quanity{
	flex: 0 0 20%;
	max-width: 20%;
	justify-content: space-evenly;
}
.quantity-input {
	min-width: 20%;
	flex: 0 0 20%;
	text-align: center;
	border: 0;
	font-size: 15px;
	color: var(--gray-dark);
}
.qty-action{
	border: 1px solid var(--border-color);
	flex: 0 0 22px;
	height: 22px;
	align-self: center;
	border-radius: 50%;
	cursor: pointer;
	line-height: 19px;
}
.product-table-wrapper .product-price {
	flex: 0 0 10%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.price{
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.remove {
	flex: 0 0 10%;
	max-width: 10%;
	cursor: pointer;
	justify-content: center;
}
.remove-icon {
	width: 16px;
}
/*add coupon section*/
.back-img {
	width: 30px;
	cursor: pointer;
	position: absolute;
	top: 11px;
}
.apply-coupon-wrap{
	position: relative;
}
.apply-coupon-text{
	padding: 9px;
}
.hide-input{
	position: absolute;
	width: 100% !important;
	visibility: hidden;
	top: -1px;
	right: -455px;
	transition: 0.3s all;
}
.hide-input input,
.entered-coupon input{
	width: calc(100% - 100px);
	padding-left: 35px;
	border: 0;
	font-size: 15px;
	height: 42px;
	visibility: hidden;
	opacity: 0;
	border-bottom: 1px solid var(--border-color);
}
.entered-coupon input{
	width: calc(100% - 130px) !important;
	border-bottom: 0 !important;
}
.show-input{
	right: 0px;
	visibility: visible;
}
.show-input input{
	visibility: visible !important;
	opacity: 1 !important;
}
.apply-coupon-btn,
.cancel-button {
	background: var(--button-color);
	border: 0;
	color: #fff;
	width: 100px;
	height: 44px;
}
.cancel-button {
	background: var(--gray-dark); 
}
.coupon-response{
	font-size: 14px;
	padding: 4px 10px;
	display: block;
	text-align: left;
	color: var(--error-message);
}
.entered-coupon input{
	background:#fff !important;
	opacity: 0.7 !important;
	visibility: visible !important;
}
.correct-img-wrap{
	width: 30px;
	display: inline-block;
}
/*checkout popup*/
.pos-row{
	margin-bottom: 25px;
}
.title-payment-mode{
	color: var(--black-color);
	text-shadow: 0px 0px 0px;
}
.payment-method-button {
	background: rgba(255, 255, 255, 0.26);
	color: #666;
	padding: 9px 12px;
	border-radius: 4px;
	border: 2px solid #e4e4e4;
	font-size: 14px;
	text-shadow: 0px 0px 0px;
	transition: 0.3s all;
}
.payment-method-button.selected {
	background: var(--button-color);
	color: #fff;
	border: 2px solid #fff;
}
.field-wrapper{
	
}
.pos-row label {
	flex: 0 0 33%;
	text-align: right;
	margin-right: 25px;
	font-size: 15px;
}
.amount-wrapper{
	flex:0 0 50%;
}
.field-with-currency{
	display: flex;
}
.currency-symbol {
    font-size: 15px;
    line-height: 39px;
    padding: 0px 15px;
    background-color: #f1f1f1;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
	border-right: 0;
	flex: 0 0 13%;
}
.field-with-currency input {
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
	border: 1px solid var(--border-color);
	font-size: 15px;
    padding: 0px 10px;
    flex: 0 0 85%;
}
.field-with-currency input[disabled]{
	background-color: #f1f1f1;
}
.service-wrap{
	position: relative;
}
.select-field::after {
	content:'';
	position: absolute;
	right: 10px;
	top: 14px;
	width: 8px;
	height: 8px;
	border-right: 1px solid var(--black-color);
	border-bottom: 1px solid var(--black-color);
	transform: rotate(45deg);
	cursor: pointer;
}
.service-name-type{
	background: #fff;
	border: 1px solid var(--border-color);
	height: 40px;
	padding: 0px 10px;
	border-radius: 4px;
	font-size:15px;
	width: 100%;
	cursor: pointer;
	-webkit-appearance: none;
}
.transaction-id-input{
	border-top-left-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	flex: 0 0 100% !important;
	height: 38px;
}
/* media */
@media screen and (min-width:992px) and (max-width:1200px){
	.product-cart-info{
		flex: 0 0 40%;
		max-width: 40%;
	}
	.product-price-wrapper {
		flex: 0 0 12%;
		max-width: 12%;
	}
	.quanity {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.product-table-wrapper .product-price {
		flex: 0 0 13%;
		max-width: 13%;
	}
}