.error-message {
	background: #ffe0e0;
	margin-bottom: 20px;
	color: red;
	padding: 10px 10px;
	font-size: 13px;
	border-radius: 3px;
	text-align: left;
	text-shadow: 0px 0px 0px;
}
.error-message p{
	font-size: 15px;
}
.login-form-wrapper {
	position: unset !important;
	width: 380px;
	margin: 12% auto;
}
.login-form {
	box-shadow: 0 .4rem 2.4rem 0 rgba(0,0,0,.15);
	padding: 30px;
	text-align: left;
	background-color: #fff;
}
.login-title{
	color: var(--black-color);
	font-size: 25px;
	font-weight: bold;
}
.sub-title {
	color: var(--light-black-color);	
	font-size: 15px;
	margin-bottom: 30px;
	margin-top: 10px;
}
.input-field {
	margin-bottom: 20px;
}
.input-field input {
	width: 100%;
	border: 1px solid var(--border-color);
	height: 40px;
	border-radius: 4px;
	color: var(--gray-color);
	padding: 8px;
	font-size: 14px;
}
.submit-btn {
	width: 100%;
	height: 40px;
	background-color: var(--button-color);
	border: 1px solid var(--button-color);
	color: #fff;
	border-radius: 4px;
	display: block;
}
.submit-btn[disabled]{
	opacity: 0.3;
}

/*ripple button loader*/
.ripple{
	text-decoration: none;
	color: #fff;
	width: 50px;
	height: 50px;
	background-color: #FFF;
	border-radius: 100px;
	animation: ripple .7s linear infinite;
	position: absolute;
	left: 50%;
	margin-left: -25px;
	top: 50%;
	margin-top: -25px;
}

@media (max-width: 420px){
	.login-form-wrapper {
		width: calc(100% - 30px);
		left: 15px;
		margin-left: 0;
		padding: 30px 15px;
	}
}