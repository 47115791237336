.refund-wrapper{
    transition:0.3s all;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
    background: #fff !important;
    height: 100vh;
    right: -1000px;
    overflow-y: auto;
    position: fixed;
    top: 0;
    z-index: 9;
    opacity: 0;
    width: 1000px;
    visibility: hidden;
}
.overlay{
    width: 100%;
    z-index: 999;
    background: rgba(0,0,0,.7);
    height: 100%;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
}
.show-refund .refund-wrapper{
    right: 0;
    visibility: visible;
    opacity: 1;
}
.show-refund .overlay{
    display: block;
    z-index: 9;
}
.refund-inner-wrapper{
    padding-bottom: 70px;
}
.refund-header{
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,.3);
	padding: 15px 15px 8px 15px;
	background: #e6e6e6;
}
.refund-header h5{
    font-size: 20px;
    font-weight: bold;
}
.close-icon{
    font-weight: bold;
    cursor: pointer;
    font-size: 17px
}
.enter_order_wrapper{
    width:400px;
}
.get_detail_btn{
    
}
.refund-input{
    color: var(--black-color) !important;
    font-size: 14px !important;
    height: 40px !important;
}
.refund-type-label {
	color: var(--black-color);
	text-shadow: 0px 0px 0px;
	font-size: 15px;
}
.refund-inner-wrapper .order-modal-body{
    padding-top: 30px;
}
.refund-footer{
    background: #fff;
	box-shadow: 0px 0px 4px 0px rgba(0,0,0,.2);
	padding: 15px 15px;
	position: fixed;
	bottom: 0;
	width:inherit;
}
/* media */
@media screen and (min-width:768px) and (max-width:991px){
    .refund-wrapper{
        width:700px;
    }
}