body{
	font-family: var(--site-font);
}
.orders-wrapper {
    background: #fff;
    z-index: 99999;
    width: calc(100% - 280px);
	transition: 0.3s all;
	animation: 0.3s leftToRight;
	top: 0;
    bottom:0;
    right: 0;
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;
}
.overlay.order-details-wrapper {
	width: 281px;
	opacity: 0.2;
}
@-webkit-keyframes leftToRight {
    0% {right: -900px;}
    100% {right: 0px;}
}
@keyframes leftToRight {
    0% {right: -900px;}
    100% {right: 0px;}
}

.order-modal-header{
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,.3);
	padding: 15px 15px 15px 15px !important;
	background: #e6e6e6;
}
.order-modal-header h1 {
	font-size: 20px;
	font-weight: bold;
}
.order-modal-header span {
	font-weight: bold;
	cursor: pointer;
	font-size: 17px;
}
.order-modal-body{
	padding-bottom: 100px !important;
}
.order_table_inner table{
	table-layout: fixed;
}
.order_table_inner table td span {
	font-size: 15px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block;
}
.order-modal-footer {
	background: #fff;
	box-shadow: 0px 0px 4px 0px rgba(0,0,0,.2);
	padding: 15px 15px;
	position: fixed;
	bottom: 0;
	width:inherit;
}
.view-order,
.view-order:hover {
	padding: 3px 15px !important;
	margin:0 !important;
}