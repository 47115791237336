.rsdt.rsdt_container {
    overflow: auto;
    background: #fff;
}

.rsdt.rsdt_highlight {
    background-color: yellow;
}

.rsdt.rsdt_sortable {
    margin-left: 10px;
}

.rsdt.rsdt_sortable_icon:after {
    font-size: 15px;
    font-style: normal;
    content: '\21D5';
    cursor: pointer;
}
.rsdt.rsdt_sortable_asc:after {
    font-size: 15px;
    font-style: normal;
    content: '\2193';
    cursor: pointer;
}
.rsdt.rsdt_sortable_desc:after {
    font-size: 15px;
    font-style: normal;
    content: '\2191';
    cursor: pointer;
}
.rma_id {
    display: none;
}

.rsdt_container table th {
    font-size: 13px;
    font-weight: 500;
    color: #000;
}
.rsdt_container table td {
    font-size: 13px;
    color: #6d5353;
}
@media (max-width: 800px) {
    .rsdt_container {
        background: transparent !important;
    }
    .rsdt_container table thead {
        display: none;
    }
    .rsdt_container table,
    .rsdt_container table tr,
    .rsdt_container table td,
    .rsdt_container table tbody {
        display: block;
    }
    .rsdt_container table tr {
        background: #fff;
        margin-bottom: 10px;
    }
    .rsdt_container table td::before {
        content: attr(data-attribute);
        color: #000;
        font-weight: 500;
    }
    .rsdt_container table td span {
        float: right;
    }
}
