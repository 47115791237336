:root{
	--border-color:#e4e4e4;
	--light-black-color:#636363;
	--button-color:#fc4445;
	--black-color: #0b172a;
	--gray-color:#666666;
	--gray-dark:#333333;
	--white-color:#ffffff;
	--error-message:red;
	--error-msg-bg: #ffe0e0;
	--success-message:#007e0e;
	--success-message-bg: #d1f4da;
	--background-color:#f5f7f9;
	--input-placeholder:#b3b3b3;
	--site-font: roboto, sans-serif;
}
body{
	font-family: var(--site-font);
}