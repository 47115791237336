.table-wrapper{
    margin:20px auto;
    border:1px solid #e4e4e4;
}
.responsive{
    background-color: white
}
.responsive-img{
    width: 90px;
    padding: 10px 0px;
}
.receipt-header-td{
    border-bottom: 1px dashed var(--border-color);
}
.receipt-top-header-content{
    width: calc(100% - 82px);
    padding-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
}
table.header-info{
    margin: 15px 0px;
}
.info-label{
    font-size: 14px;
    text-transform: capitalize;
    text-align: left;
    padding-left: 10px;
    width: 1%;
    white-space: nowrap;
    color:var(--black-color);
}
.info-value{
    font-size: 14px;
    text-align: left;
    color: #666;
    padding-left: 5px;
}
.table-title{
    border-bottom: 1px solid #e4e4e4;
    line-height: 34px;
    font-size: 14px;
    text-transform: capitalize;
    padding: 8px;
    background: #ededed;
}
.table-title:nth-child(1){
    text-align: left;
}
.table-value{
    line-height: 30px;
    font-size: 14px;
    border-bottom: 1px solid #e4e4e4;
    padding: 8px;
    color: #666;
}
.table-value:nth-child(1) {
	line-height: 20px;
    text-align: left;
    word-wrap: break-word;
}
.subtotal-label{
    text-align: right;
    font-size: 14px;
    text-shadow: 0px 0px 0px;
    line-height: 24px;
    text-transform: capitalize;
}
.subtotal-value{
    text-align: right;
    font-size: 14px;
    padding-right: 10px;
}
.total-label{
    text-align: right;
    font-size: 14px;
    text-shadow: 0px 0px 0px;
    line-height: 40px;  
    text-transform: capitalize;
    font-weight: 700;
}
.total-value{
    text-align: right;
    font-size: 14px;
    padding-right: 10px;
    font-weight: 700;
}
.contact-us-title{
    padding: 13px 0 12px 0px;
    line-height: 10px; 
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-top: 1px dashed #e4e4e4;
}
.address-info{
    padding: 10px 10px 0px 10px;
    line-height: 23px; 
    text-align: left;
    font-size: 14px;
}
.contact-info-title{
    font-weight: bold;
}
.thank-you-section{
    width: 100%;
    border-top: 1px dashed #e4e4e4;
}
.thank-you-content {
	padding: 15px;
	font-size: 15px;
}
@media only screen and (max-width:640px){
    .responsive{
        width: 100% !important;
    }
}