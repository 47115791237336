.print-button{
	background: var(--button-color);
	color: #fff;
	border: 0;
	padding: 7px 10px;
	border-radius: 4px;
	font-size: 15px;
}
.print-button{
    background-color: var(--gray-color);
}
.print-button:hover{
    color:#fff;
    text-decoration: none;
}
.order-info-label {
	font-size: 15px;
	text-shadow: 0px 0px 0px;
}
.order-info-label + span {
	font-size: 15px;
	padding-left: 5px;
	text-shadow: none !important;
	color: var(--gray-color);
}
.order-items-title{
    font-weight: 600;
}

.custom-table {
	margin: 17px auto;
    width: calc(100% - 30px) !important;
    text-align: left;
    border: 1px solid var(--border-color);
    font-size: 15px;
}
.custom-table thead tr{
	position: sticky;
	top: 0px;
	background: #fff;
	box-shadow: 0px 1px 0px 1px rgba(182, 182, 182, 0.3);
}
.custom-table td{
	vertical-align: middle;
}
.sub-heading {
    color: var(--black-color) !important;
	font-weight: normal;
	text-align: left;
	font-size: 15px;
	vertical-align: middle !important;
}
.total {
	font-size: 16px !important;
	font-weight: 600;
}