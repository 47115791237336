.add-inventory .overlay{
	display: block;
	width: 281px;
	z-index: 99999;
}
.view-order-wrapper {
	padding-top:35px;
}
/* details form css */
.inventory-details-form {
	width: calc(100% - 600px);
	border: 1px solid var(--border-color);
	margin: 0 auto;
}
.inv-pid,
.inv-pname,
.inv-pqty {
	display: flex;
	border-bottom: 1px solid var(--border-color);
	padding: 10px;
}
.inv-label {
	flex: 0 0 50%;
	text-align: right;
	padding-right: 30px;
	color: var(--black-color);
	text-shadow: 0px 0px 0px;
	font-size: 15px;
}
.inv-pqty .inv-label {
	line-height: 30px;
}
.inv-value {
	color: var(--gray-dark);
	font-size: 15px;
}
.inv-input-wrap {
	flex: 0 0 20%;
}
.inv-input-wrap input{
	width: 100%;
	text-align: center;
	font-size: 15px;
}
.secondary-btn {
	background: #ffdede;
	border: 1px solid var(--button-color);
	padding: 7px 25px;
	margin: 10px;
	border-radius: 4px;
	transition:0.3s all;
	font-size: 15px;
}
.secondary-btn:hover{
	background-color: var(--button-color);
	color:#fff;
}
@media screen and (min-width:768px) and (max-width:991px){
	.enter_order_wrapper{
		width:auto !important;
	}
	.inventory-details-form{
		width: 100%;
	}
}