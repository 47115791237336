.pagination-wrapper {
	display: inline-block;
	box-shadow: 0px 0px 11px 0px rgba(0,0,0,.1);
	border-radius: 4px;
}
.pagination_btns {
	margin: 6px;
	border: 0;
	font-size: 15px;
	background: #fff;
}
.pagination_btns.active{
	background: var(--button-color) !important;
	color: #fff !important;
	border-radius: 50% !important;
	width: 24px;
	height: 24px;
	text-align: center;
	line-height: 18px;
}
.pagination_btns:hover {
    color: var(--button-color);
    background: #fff;
}