body{
  font-style: var(--site-font);
}
input::placeholder {
  color: var(--input-placeholder);
}
input[type="search"]::-webkit-search-decoration, 
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input:focus{
  box-shadow: 0px 0px 6px 0px rgba(80, 80, 80, 0.2);
}
.wrapper{
  overflow-x: hidden;
}
.error-message {
  background:var(--error-msg-bg);
  margin-bottom: 20px;
  color: var(--error-message);
  padding: 10px 10px;
  font-size: 14px;
  border-radius: 3px;
  text-align: left;
  text-shadow: 0px 0px 0px;
}
.error-message img{
  vertical-align: middle;
}
.success-message {
  background:var(--success-message-bg);
  padding: 10px;
  color: var(--success-message);
  font-size: 14px;
  text-align: left;
  text-shadow: 0px 0px 0px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #5eb467;
}
/*sidebar css*/
.side-bar-navigation {
  width: 280px;
  transition: all 0.3s;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
  background: #fff !important;
  height: 100vh;
  left: -280px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  z-index: 9999;
}
.side-bar-navigation .side-nav-link {
  padding: 8px 10px;
  font-size: 15px;
  display: block;
  text-align: left;
  color: var(--gray-dark);
  border-bottom: 1px solid var(--border-color);
  transition: 0.3s all;
}
.side-bar-navigation .side-nav-link:hover,
.side-bar-navigation .side-nav-list.active > .side-nav-link{
  color: var(--button-color);
  padding-left: 15px;
  background: #f2f2f2;
  text-decoration: unset;
}
.show-sidebar .side-bar-navigation{
  left: 0;
}
.show-sidebar .overlay{
  display: block;
}
.overlay{
  width: 100%;
  z-index: 999;
  background: rgba(0,0,0,.7);
  position: fixed;
  height: 100%;
  display: none;
}
.sidebar-header{
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--button-color);
  color: #fff;
}
.sidebar-header .close-side,
.sidebar-header .close-side:hover {
  outline: none !important;
  text-decoration: unset;
  color: #fff;
  font-size: 17px;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 23px;
  height: 24px;
  line-height: 19px;
}
.order-table-overlay {
  content: "";
  width: 280px;
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 10;
  background-color: rgba(0,0,0,0.2);
}
/*left category page*/
.left-section-wrapper{
  height: calc(100% - 160px);
}
.dashboard-design-page{
  width: 100%;
}
.header-items-wrapper {
  flex: 0 0 100%;
}
.hamburger-icon{
  height: 40px;
  width: 25px;
  padding: 0 !important;
}
.hamburger-icon:focus{
  box-shadow: none !important;
}
.hamburger-icon span {
  height: 2px;
  margin: 5px auto;
  margin-top: 5px;
  display: block;
  background: var(--button-color);
}
.search-form-wrapper {
	position: relative;
}
.search-bar-custom,
.customer_search input{
  box-shadow: 0px 0px 6px 0px rgba(80, 80, 80, 0.2);
  font-size: 14px;
  height: 40px;
  border-radius: 3px;
  border: 0;
}
.search-form-wrapper img,
.search-img,
.customer-cross-img {
	position: absolute;
	right: 10px;
  top: 12px;
  cursor: pointer;
}
.search-crosss-img,
.customer-cross-img{
	width: 12px;
	height: 12px;
	top: 15px !important;
}
.barcode-checkbox {
  position: relative;
}
.barcode-checkbox input{
  vertical-align: middle;
  cursor: pointer;
  color: var(--gray-color);
  -moz-appearance: none;
  -webkit-appearance: none;
}
.bar-code-text {
  font-size: 14px;
  color: var(--black-color);
  padding-left: 20px;
  cursor: pointer;
  margin-bottom: 0px;
}
.barcode-checkbox input[type=checkbox]:checked + .bar-code-text:after{
  display: block;
}
.bar-code-text:before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border: 1px solid var(--button-color);
  left: 0;
  top: 6px;
  border-radius: 2px;
}
.bar-code-text:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 7px;
  left: 3px;
  top: 6px;
  border-top: 1px solid var(--button-color);
  border-right: 1px solid var(--button-color);
  transform: rotate(137deg);
  display: none;
}
/*all category section*/
.filter-area {
  background: var(--background-color);
  padding: 20px 15px;
}
.dot{
  border-radius: 50%;
  display: block;
  height: 4px;
  margin: 3px auto;
  width: 4px;
  background: #fff;
}
.product-head {
  background: #fff;
  padding: 10px;
  position: relative;
  text-align: left;
  font-size: 15px;
  border-radius: 3px;
}
.product-head .category{
  color: var(--gray-dark);
  text-decoration: none;
  margin-right: 15px;
  cursor: pointer;
  font-size: 15px;
}
.product-head .category.active{
  color: var(--button-color);
  text-decoration: underline;
}
.category-show{
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.15);
  left: 0;
  overflow: auto;
  position: absolute;
  text-align: left;
  top: 44px;
  transition: all .3s;
  width: 100%;
  visibility: hidden;
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  height: 0px;
}
.category-show.active{
  visibility: visible;
  height: 300px;
}
.cat-toggle {
  width: 44px;
  height: 42px;
  background: var(--button-color);
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0px 3px 3px 0px;
}
.cat-toggle img{
  transition: 0.3s all;
}
.showMenu img{
  transform: rotate(180deg);
}
.parent-cat-list {
  list-style-type: none;
  flex: 0 0 33%;
  padding: 10px;
}
.parent-title,
.parent-title:hover {
  text-decoration: unset;
  font-size: 14px;
  border-bottom: 1px solid var(--button-color);
  color: var(--button-color);
  margin-bottom: 10px;
  display: inline-block;
  text-shadow: 0px 0px 0px;
  text-transform: uppercase;
  cursor: pointer;
}
.child-cat {
  padding-left: 0px;
  list-style: none;
}
.inner-child-title{
  text-decoration: unset;
  margin-bottom: 3px;
  text-shadow: 0px 0px 0px;
  color: var(--gray-dark);
  cursor: pointer;
}
.inner-child-title:hover,
.child-cat-inner a:hover {
  color: var(--button-color) !important;
  text-decoration: unset;
}
.second-level-cat .inner-child-title{
  text-shadow: unset;
  color: var(--gray-dark);
  text-decoration: unset;
  padding-left: 8px;
}
/*product tile*/
.left-content-wrapper{
  height: 100%;
}
.left-wrapper{
  height: 100%;
}
.custom-scroll div:nth-child(3) div {
  background-color: rgba(0,0,0,.5) !important;
  height: 30px;
  border-radius: 7px !important;
  width: 6px !important;
  left: 0px !important;
}
.custom-scroll > div{
  background-color: var(--background-color);
  overflow-x: hidden !important;
  bottom:0 !important;
}
.noproduct-wrapper{
  margin: 15% auto;
}
.no-product {
  font-size: 16px;
  flex: 0 0 100%;
  color: var(--black-color);
}
.content-wrapper{
  padding: 0px 15px;
  background-color: var(--background-color);
}
.product-tiles-wrapper{
  animation-name: productFadeIn;
  animation-duration: 2s;
  padding-bottom: 20px;
}
@-webkit-keyframes productFadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes productFadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
.product-box {
  background: #fff;
  padding: 12px;
  text-align: center;
  cursor: pointer;
  flex: 0 0 24%;
  transition: 0.3s all;
  border-radius: 3px;
  margin-bottom: 8px;
  margin-right: 8px;
}
.product-box:nth-child(4n){
  margin-right: 0;
}
.product-box:hover{
  box-shadow: 0px 0 4px 0px rgba(0,0,0,.15);
  text-decoration: none;
}
.product-box.low-stock,
.product-box.out-of-stock{
  position: relative;
}
.product-box.out-of-stock{
  opacity: 0.6;
  pointer-events: none;
}
.product-box.low-stock::before,
.product-box.out-of-stock::before {
  position: absolute;
  font-size: 10px;
  background: rgba(255, 0, 0, 0.61);
  top: 9px;
  color: #fff;
  text-shadow: 0px 0px 0px;
  left: 5px;
  padding: 9px 4px 0px 4px;
  width: 40px;
  height: 40px;
  line-height: 12px;
  border-radius: 50%;
  text-align: center;
}
.product-box.low-stock::before {
  content: 'Low Stock';
}
.product-box.out-of-stock::before {
  content: 'Out Of Stock';
}
.img-wrap img {
  max-height: 140px;
  min-height: 140px;
}
.product-sku {
	font-size: 14px;
	text-shadow: 0px 0px 0px;
}
.product-name {
  font-size: 15px;
  color: var(--black-color);
  margin:10px auto;
}
.product-price {
  font-size: 15px;
  color:var(--button-color);
}
.stock-info {
  font-size: 14px;
  margin: 0;
  color: #096 ;
}
/*right cart section*/
.cart-wrapper-right {
  height: 100vh;
  border-left: 1px solid #eee;
  background:#fff;
}
/*right header section*/
.unsticky-wrapper {
  overflow-y: auto;
  height: calc(100% - 226px);
  overflow-x: hidden;
}
.right-cart-section {
  width: 100%;
  padding: 6px 0;
  border-bottom: 1px solid var(--border-color);
  position: relative;
}
.user-profile {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  outline: none;
  position: relative;
  line-height: 20px;
}
.user-profile:hover{
  text-decoration: none;
}
.username {
  font-size: 15px;
  color: var(--button-color);
  text-align:left;
}
.user-submenu::before {
	content: '';
	position: absolute;
	width: 10px;
	height: 10px;
	background: white;
	border-top: 1px solid var(--border-color);
	border-left: 1px solid var(--border-color);
  transform: rotate(45deg);
  top: -6px;
  right: 6px;
}
.user-submenu{
  position: absolute;
  z-index: 1;
  border: 1px solid var(--border-color);
  background: #fff;
  visibility: hidden;
  opacity: 0;
  left: 13px;
  box-shadow: 0px 2px 6px 1px rgba(0,0,0,.1);
  top: 54px;
  transition: 0.3s all;
  padding:5px;
}
.user-submenu.opensubmenu{
  visibility: visible;
  opacity: 1;
  top: 51px;
}
.dropdown-icon {
  width: 8px;
  height: 8px;
  margin-left: 5px;
  vertical-align: middle;
  margin-top: 2px;
}
.logout-btn{
  color:var(--black-color);
  font-size: 15px;
}
.logout-btn:hover{
  color:var(--button-color);
}
.logout-icon{
  transform: rotate(-90deg);
}
.refund-btn{
  background: var(--button-color);
  color: #fff;
  border: 0;
  padding: 5px 11px;
  font-size: 15px;
  border-radius: 4px;
}
.cart-text,
.cart-wrapper:hover{
  font-size: 15px;
  text-decoration: unset !important;
  color: var(--button-color);
}
.cart-wrapper {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-right: 10px;
}
.cart-value {
  position: absolute;
  background: var(--button-color);
  color: #fff;
  height: 15px;
  width: 15px;
  text-align: center;
  border-radius: 50%;
  top: -7px;
  right: -2px;
  font-size: 10px;
}
/*search customer section*/
.error-list {
  text-align: center !important;
  margin-top: 30px;
}
.no-item-text {
  font-size: 15px;
  color: var(--gray-dark);
  margin-top: 10px;
}
.customer_search_wrap > div:nth-child(1){
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
  font-size: 15px;
}
.customer-search-text{
  padding: 10px;
  cursor: pointer;
  margin-bottom: 0px;
  border-bottom: 1px solid var(--border-color);
  font-size: 15px;
  color: var(--black-color);
}
.customer_search{
  position: relative;
}
.set-cutomer-list {
  position: absolute;
  z-index: 9;
  background: #fff;
  width: 100%;
  overflow: auto;
  height: 200px;
  box-shadow: 0px 1px 10px 0px rgba(0,0,0,.1);
}
.set-cutomer-list li {
  font-size: 14px !important;
  padding: 3px 0px;
  list-style-type: none;
  text-align: left;
  color: var(--gray-dark);
  cursor: pointer;
}
.set-cutomer-list li:hover {
  color: var(--button-color);
}
.no-item {
  margin: 6% auto;
  text-align: center !important;
}
.customer_search input {
  padding: 10px;
  width: 100%;
}
.no-customer-error,
.no-customer-error:hover{
  color:var(--gray-color) !important;
  margin:8% auto;
  text-align: center !important;
  cursor: default !important;
}
/*add more section*/
.add-more{
  padding: 0px 10px;
  border-bottom: 1px solid var(--border-color);
}
.info-button, 
.info-button:hover {
  background: #fff;
  font-size: 14px;
  margin: 10px 0;
  padding: 7px;
  position: relative;
  text-decoration: none;
  width: 100%;
  color: #000;
  border: 1px solid var(--black-color);
  border-radius: 3px;
}
.info-button .Cart-icon{
  width: 16px;
}
/*add new cart section*/
.tab-carts {
  padding: 10px 10px 10px 10px;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,.1);
}
.my-cart-title {
  color: var(--black-color);
  text-shadow: 0px 0px 0px;
  font-size: 18px;
  margin-bottom: 0;
}
/*minicart billing section*/
.billing-wrapper{
  box-shadow: 0 -2px 3px 0 rgba(0,0,0,0.1);
  position: absolute;
  bottom: 0;
  width: 100%;
}
.list-items {
  position: relative;
  padding: 9px 10px !important;
  background-color: #fff;
  border-bottom: 1px solid var(--border-color);
}
.list-items:first-child{
 border-top-left-radius: 0 !important;
 border-top-right-radius:0 !important;
}
.add-coupon-img{
  cursor: pointer;
}
.manage-font {
  font-size: 15px;
  color: var(--black-color);
}
.cart-tax-amount {
  text-shadow: 0px 0px 0px;
  padding-left: 10px;
  color: var(--black-color);
}
.cart-subtotal {
  text-shadow: 0px 0px 0px;
  color: var(--black-color);
}
.tax-input{
  color:var(--button-color) !important;
  font-size: 15px;
}
.checkout {
  border-radius: 0;
}
.checkout-amt {
  font-size: 17px;
  font-weight: 900;
}
.checkout-btn {
  background: var(--button-color);
  border: 0;
  height: 52px;
  width: 110px;
  color: #fff;
}
/*media*/
@media screen and (min-width: 768px) and (max-width: 950px){
  .product-box {
    flex: 0 0 47%;
  }
  .product-name {
    width: 130px;
  }
  .product-price {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: unset !important;
  }
  .cart-text{
    display: none;
  }
  .parent-cat-list{
    flex: 0 0 50%;
  }
}
@media screen and (min-width:951px) and (max-width:1030px){
  .product-box {
    flex: 0 0 31%;
    max-width:31%;
  }
  .product-box .product-price {
    margin: 0 auto;
  }
  .product-box:nth-child(4n){
    margin-right: 8px;
  }
}
@media screen and (min-width:992px) and (max-width:1001px){
  .bar-code-text {
    font-size: 13px;
    text-align: left;
  }
}
@media screen and (min-width:992px) and (max-width:1165px){
  .add-customer-sction,
  .add-custome-product{
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .add-customer-sction {
    padding-right: 15px !important;
  }
}