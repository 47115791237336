.rsdt.rsdt-column-toggles {
    margin-bottom: 20px;
}

.rsdt.rsdt-column-toggles.toggle {
    display: inline-block;
    margin-left: 5px;
    margin-right: 10px;
    margin-bottom: 0;
}

.rsdt.rsdt-column-toggles.toggle > label {
    margin-left: 5px;
    cursor: pointer !important;
    cursor: hand !important;
}
