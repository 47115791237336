body{
    font-family: var(--site-font);
}
.pos-customer-form-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    z-index: 99;
    overflow: auto;
}
.pos-form-inner-wrap{
    width: 600px;
    margin: 7% auto;
}
.pos-customer-form-inner{
    overflow: auto;
    border-radius: 3px;
    background: white;
    border-radius: 4px;
    transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    box-shadow: 0px 0px 12px 2px rgba(0,0,0,.2);
}
.pos-customer-form-inner.open-modal {
    animation: .5s formTranform;
}
@keyframes formTranform {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}
.pos-modal-header {
    padding: 10px !important;
    background: var(--button-color);
    text-align: left;
    border-bottom:0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.modal-title {
    font-size: 19px;
    color: #fff;
}
.pos-customer-field {
    flex: 0 0 49%;
}
.pos-customer-field input::placeholder {
    font-size: 12px;
    color: #8c8989;
}
.pos-customer-field input,
.pos-customer-field input:focus{
    font-size: 15px;
    color:var(--black-color);
}
.control-label {
    font-size: 13px;
    text-shadow: 0px 0px 0px;
    margin-bottom: 2px;
}
.pos-modal-footer{
    border-top: 0 !important;
}
.add-customer-button,
.close-button {
    color: #fff;
    border: 0;
    font-size: 15px;
    border-radius: 4px;
}
.add-customer-button{
    background: var(--button-color);
    padding: 7px 15px;
}
.close-button,
.close-button:hover {
    background: var(--gray-color);
    padding: 7px 12px;
    text-decoration: none;
    color: #fff;
}
.field-error {
    font-size: 12px !important;
    background-color: #fff;
}
