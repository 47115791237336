.loader-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
    background: rgba(255,255,255,.8);
    transition: 0.5s all ease-in-out;
    -webkit-transition: 0.5s all ease-in-out;
    -moz-transition: 0.5s all ease-in-out;
} 
.active-loader{
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 5s;
    animation-duration: 1s;
}      
@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.pinkBg {
    background-image: linear-gradient(90deg, #ff2323, #fd8a55);
}
.intro-banner-vdo-play-btn{
    height:40px;
    width:40px;
    position:absolute;
    top:50%;
    left:50%;
    text-align:center;
    border-radius:100px;
    z-index:1
}
.intro-banner-vdo-play-btn i{
    line-height:56px;
    font-size:30px
}
.intro-banner-vdo-play-btn .ripple{
     position: absolute;
     width: 100px;
     height: 100px;
     z-index: -1;
     left: 50%;
     top: 50%;
     opacity: 0;
     margin: -50px 0 0 -50px;
     border-radius: 100px;
     animation: ripple 1.8s infinite;
}
@-webkit-keyframes ripple{
    0%{
        opacity:1;
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
@keyframes ripple{
    0%{
        opacity:1;
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
.intro-banner-vdo-play-btn .ripple:nth-child(2){
    animation-delay:.3s;
    -webkit-animation-delay:.3s
}
.intro-banner-vdo-play-btn .ripple:nth-child(3){
    animation-delay:.6s;
    -webkit-animation-delay:.6s
}
